import { createContext, useContext } from 'react';
import { User } from '../../types';

/**
 * Rest of the application will not see this value
 * and will see a response from server instead
 */
const defaultValue: User = {
  email: '',
  name: '',
  picture: '',
};

export const UserProfileContext = createContext(defaultValue);

export function useUser() {
  const user = useContext(UserProfileContext);
  return user;
}
