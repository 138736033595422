export function LoadingImage() {
  return (
    <div className="flex flex-col items-center justify-center">
      <img src="/images/loading.gif" alt="loading image" />
      <span className="animate-pulse text-lg">
        Our AI is reading the uploaded judgement and generating headnote. This
        will take only few seconds..
      </span>
    </div>
  );
}

export default LoadingImage;
