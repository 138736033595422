import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import clsx from 'clsx';
import { Fragment } from 'react';
import { MenuItemGroup, MenuItem as MenuItemType } from '../types';

interface Props {
  items: MenuItemType[];
  menuButton: React.ReactNode | string;
}

export function MenuDropdown(props: Props) {
  const { menuButton, items } = props;

  let groupedItems: MenuItemGroup = {};
  groupedItems = items.reduce((groups, item) => {
    const groupName = item.group || 'default';
    groups[groupName] = groups[groupName] || [];
    groups[groupName].push(item);
    return groups;
  }, groupedItems);

  return (
    <Menu as="div" className="relative cursor-pointer">
      <MenuButton className="flex rounded-full p-2 hover:bg-gray-200" as="div">
        {menuButton}
      </MenuButton>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="dark:bg-dark-bg absolute right-0 z-10 mt-2 w-48 origin-top-right divide-y divide-gray-100 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:divide-gray-600 dark:ring-gray-600">
          {Object.entries(groupedItems).map(([groupKey, groupItems]) => (
            <div key={groupKey}>
              {groupItems.map(
                (option) =>
                  !option.hidden && (
                    <MenuItem key={option.name} data-testid={option.dataTestId}>
                      {({ active }) => (
                        <div
                          onClick={option.onClick}
                          className={clsx(
                            active ? 'bg-gray-100 hover:dark:bg-gray-700' : '',
                            'dark:text-dark-text dark:bg-dark-bg block px-4 py-2 text-sm text-gray-700',
                            'group flex cursor-pointer items-center',
                          )}
                        >
                          <span
                            className={clsx('mr-4', !option.icon && 'hidden')}
                          >
                            {option.icon || null}
                          </span>
                          <span>{option.name}</span>
                        </div>
                      )}
                    </MenuItem>
                  ),
              )}
            </div>
          ))}
        </MenuItems>
      </Transition>
    </Menu>
  );
}

export default MenuDropdown;
