interface Props {
  fileUrl: string;
  title: string;
}

export function DocViewer(props: Props) {
  const { fileUrl, title } = props;

  return (
    <div className="flex h-full flex-col rounded-md px-2 py-4 shadow-lg">
      <div className="flex h-20 items-center justify-between border-b border-gray-400 p-2">
        <h2 className="text-xl font-bold text-gray-700">{title}</h2>
      </div>
      {fileUrl && (
        <iframe
          src={`${fileUrl}#toolbar=0&navpanes=0`}
          style={{ border: 0 }}
          className="h-full w-full border-none"
        />
      )}
    </div>
  );
}

export default DocViewer;
