import { Footer, Header } from '../../ui-elements';
import { useScrollToTopOnLoad } from '../../util';
import { getAllSections } from './util';

export function RefundPolicy() {
  const allSections = getAllSections();
  useScrollToTopOnLoad();
  return (
    <>
      <Header />
      <div className="px-6 py-4 md:px-20 md:py-14">
        <h1 className="mb-8 text-center text-4xl text-[#1E0E62]">
          Refund Policy
        </h1>
        <div>
          {allSections.map((section) => (
            <div key={section.id} className="my-8">
              <div className="my-4 text-2xl font-bold">{section.title}</div>
              <div>{section.content}</div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}
