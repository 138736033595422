import jsPDF from 'jspdf';
import { DocSectionConfig, DocToolParams } from '../../types';
import { DEFAULT_FONT, DEFAULT_FONT_SIZE, DEFAULT_MARGIN } from './constants';
import { palatino } from './palatino-font';

export const exportToPDF = (params: DocToolParams) => {
  const { textWithCustomFormat, filename, text = '' } = params;
  const doc = new jsPDF();
  doc.addFileToVFS('Palatino.ttf', palatino);
  doc.addFont('Palatino.ttf', 'Palatino', 'normal');
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const maxLineWidth = pageWidth - DEFAULT_MARGIN * 2;
  const lineHeight = 5;

  doc.setFont(DEFAULT_FONT);

  const addSection = (
    sectionText: string,
    sectionConfig: DocSectionConfig,
    cursorX: number,
    cursorY: number,
  ) => {
    if (sectionConfig.font) doc.setFont(sectionConfig.font);
    doc.setFontSize(sectionConfig.fontSize || DEFAULT_FONT_SIZE);
    doc.setFont(DEFAULT_FONT, sectionConfig.isBold ? 'bold' : 'normal');

    const { extraLineBreaks, isInline } = sectionConfig;

    let finalSectionText = sectionText;

    if (isInline) {
      cursorY -= lineHeight;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [firstLine, ..._remainingLines] = doc.splitTextToSize(
        sectionText,
        maxLineWidth - cursorX,
      );
      doc.text(firstLine, cursorX + 12, cursorY);
      cursorY += lineHeight;
      finalSectionText = sectionText.slice(firstLine.length);
    }

    const lines = doc.splitTextToSize(finalSectionText, maxLineWidth);

    if (extraLineBreaks) {
      cursorY += extraLineBreaks * lineHeight;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    lines.forEach((line: any) => {
      if (cursorY + lineHeight > pageHeight - DEFAULT_MARGIN) {
        doc.addPage();
        cursorY = DEFAULT_MARGIN;
      }

      doc.text(line, DEFAULT_MARGIN, cursorY);
      cursorY += lineHeight;
      cursorX = doc.getTextWidth(line);
    });
    return [cursorX, cursorY];
  };

  let cursorY = DEFAULT_MARGIN;
  let cursorX = DEFAULT_MARGIN;

  if (textWithCustomFormat) {
    textWithCustomFormat.sections.forEach((sectionConfig) => {
      const sectionText = sectionConfig.text;
      [cursorX, cursorY] = addSection(
        sectionText,
        sectionConfig,
        cursorX,
        cursorY,
      );
    });
  } else if (text) {
    doc.setFont(DEFAULT_FONT);
    doc.setFontSize(12);
    const lines = doc.splitTextToSize(text, maxLineWidth);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    lines.forEach((line: any) => {
      if (cursorY + lineHeight > pageHeight - DEFAULT_MARGIN) {
        doc.addPage();
        cursorY = DEFAULT_MARGIN;
      }
      doc.text(line, DEFAULT_MARGIN, cursorY);
      cursorY += lineHeight;
    });
  }

  doc.save(filename);
};
