import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../../ui-base';
import { LawMatrixLogo } from '../../../ui-elements';
import { NavbarMobile } from '../navbar/NavbarMobile';

export function DasboardHeaderMobile() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  const onNavClick = () => setIsMenuOpen(false);

  return (
    <div
      className={clsx(
        'block lg:hidden',
        isMenuOpen && 'fixed inset-0 z-10 bg-white',
      )}
    >
      <div className="flex items-center justify-between border-b-2 border-gray-300 p-3">
        <Link to="/dashboard">
          <LawMatrixLogo />
        </Link>
        <Button variant="icon" onClick={toggleMenu}>
          {isMenuOpen ? (
            <XMarkIcon className="h-8 w-8" />
          ) : (
            <Bars3Icon className="h-8 w-8" />
          )}
        </Button>
      </div>
      {isMenuOpen && <NavbarMobile onClick={onNavClick} />}
    </div>
  );
}

export default DasboardHeaderMobile;
