import { Route, Routes } from 'react-router-dom';
import CreateHeadnote from './CreateHeadnote';
import DashboardHome from './DashboardHome';
import HeadnoteDetails from './headnote-details/HeadnoteDetails';
import MyHeadnotes from './my-headnotes/MyHeadnotes';

export function DashboardRoutes() {
  return (
    <Routes>
      <Route path="/" element={<DashboardHome />} />
      <Route path="/create-headnote" element={<CreateHeadnote />} />
      <Route path="/headnote/details/:docId" element={<HeadnoteDetails />} />
      <Route path="/history/" element={<MyHeadnotes />} />
    </Routes>
  );
}

export default DashboardRoutes;
