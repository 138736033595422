import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useHttpClient } from '../../util';

type RequestOptions<T> = UseQueryOptions<T, Error> & {
  queryKey: QueryKey;
  responseModifier?: (response: Response) => Promise<T>;
  /** Make an unauthenticated request by skipping the Authorization header */
  noAuth?: boolean;
};

export function useAuthenticatedRequest<T>(
  requestInfo: Request,
  options: RequestOptions<T>,
) {
  const httpClient = useHttpClient();
  const { queryKey, ...remainingOptions } = options;

  async function queryFn() {
    return httpClient<T>(requestInfo, {
      noAuth: options?.noAuth,
      responseModifier: options?.responseModifier,
    });
  }

  return useQuery<T, Error>({
    retry: false,
    refetchOnWindowFocus: false,
    queryFn: queryFn,
    queryKey,
    ...remainingOptions,
  });
}

export default useAuthenticatedRequest;
