import { Headnote } from '../../types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useHeadnoteById(docId: string) {
  const url = `/api/v1/documents/${docId}/headnotes`;
  const request = new Request(url);

  return useAuthenticatedRequest<Headnote>(request, {
    queryKey: ['headnote', { docId }],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson;
    },
  });
}

export default useHeadnoteById;
