import Links from './Links';
import { navItems } from './util';

export function Navbar() {
  return (
    <div className="hidden h-[92vh] w-64 flex-none border-r-2 border-gray-300 px-4 py-8 lg:block">
      <Links items={navItems} />
    </div>
  );
}

export default Navbar;
