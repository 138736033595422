export function UploadCloudIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg
      viewBox="0 0 95 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M62.9628 63.4489L47.2856 47.7718L31.6084 63.4489"
        stroke="#7660C5"
        strokeWidth="3.91929"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.2852 47.7718V83.0454"
        stroke="#7660C5"
        strokeWidth="3.91929"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.1684 72.8161C83.991 70.7321 87.0108 67.4344 88.7511 63.4436C90.4914 59.4527 90.8532 54.9959 89.7793 50.7767C88.7054 46.5574 86.2569 42.8159 82.8204 40.1427C79.3839 37.4694 75.155 36.0168 70.8012 36.0139H65.8629C64.6766 31.4253 62.4655 27.1654 59.3959 23.5544C56.3262 19.9434 52.4779 17.0753 48.1403 15.1656C43.8026 13.256 39.0885 12.3545 34.3523 12.529C29.6161 12.7035 24.981 13.9494 20.7957 16.1731C16.6103 18.3968 12.9835 21.5403 10.1879 25.3674C7.39228 29.1946 5.50066 33.6056 4.65523 38.269C3.8098 42.9324 4.03256 47.7268 5.30675 52.2917C6.58095 56.8567 8.87342 61.0733 12.0118 64.6247"
        stroke="#7660C5"
        strokeWidth="3.91929"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.9628 63.4489L47.2856 47.7718L31.6084 63.4489"
        stroke="#7660C5"
        strokeWidth="3.91929"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function NotesIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 121 152"
      fill="none"
      {...props}
    >
      <path
        d="M102.341 0.608614L6.0459 0.000109955C2.88119 -0.0199541 0.350183 2.70857 0.506906 5.97119L7.13172 147.187H114.29L120.24 21.0182L102.341 0.608614Z"
        fill="url(#paint0_linear_439_433)"
      />
      <path
        d="M102.486 25.4644V0.768341L120.5 21.0479L102.486 25.4644Z"
        fill="#E4AF31"
      />
      <path
        opacity="0.78"
        d="M58.3204 38.8978C66.2347 38.8579 96.618 38.6492 104.29 38.5962C105.258 38.5895 106.023 39.6281 105.981 40.8887C105.941 42.0885 105.18 43.0476 104.259 43.0595L58.3092 43.6513C57.4241 43.6627 56.689 42.7936 56.6134 41.6463L56.5943 41.356C56.5078 40.0442 57.3084 38.9028 58.3204 38.8978Z"
        fill="white"
      />
      <path
        opacity="0.78"
        d="M57.9317 48.8151C65.8461 48.7753 96.2293 48.5666 103.902 48.5136C104.869 48.5069 105.634 49.5455 105.592 50.8061C105.552 52.0059 104.792 52.965 103.87 52.9769L57.9205 53.5686C57.0354 53.58 56.3004 52.711 56.2248 51.5637L56.2056 51.2734C56.1192 49.9615 56.9197 48.8202 57.9317 48.8151Z"
        fill="white"
      />
      <path
        opacity="0.78"
        d="M23.5464 62.7639C36.8968 62.7736 88.1469 62.7548 101.088 62.7498C102.721 62.7492 104.046 63.7931 104.016 65.054C103.989 66.2542 102.737 67.2091 101.183 67.2151L23.6842 67.5197C22.1914 67.5256 20.9227 66.6515 20.7572 65.5031L20.7154 65.2126C20.5264 63.8996 21.8392 62.7627 23.5464 62.7639Z"
        fill="white"
      />
      <path
        opacity="0.78"
        d="M22.9296 73.1597C33.3214 73.1131 73.2146 72.8785 83.2883 72.8189C84.559 72.8114 85.5834 73.8496 85.5522 75.1105C85.5225 76.3107 84.5417 77.2706 83.3322 77.2833L23.0052 77.9143C21.8432 77.9264 20.8614 77.0578 20.7404 75.9103L20.7098 75.62C20.5713 74.3079 21.6008 73.1656 22.9296 73.1597Z"
        fill="white"
      />
      <path
        opacity="0.78"
        d="M23.4174 86.7678C36.7678 86.7775 88.0179 86.7587 100.959 86.7537C102.592 86.7531 103.917 87.797 103.888 89.0579C103.86 90.2581 102.608 91.213 101.054 91.2191L23.5553 91.5236C22.0625 91.5295 20.7938 90.6554 20.6284 89.507L20.5865 89.2165C20.3974 87.9035 21.7102 86.7666 23.4174 86.7678Z"
        fill="white"
      />
      <path
        opacity="0.78"
        d="M22.2537 97.3315C32.6946 97.3511 72.7766 97.3705 82.8979 97.3752C84.1746 97.3758 85.1979 98.4205 85.1593 99.6811C85.1227 100.881 84.1317 101.835 82.9165 101.84L22.3024 102.087C21.1348 102.091 20.1535 101.216 20.0384 100.068L20.0093 99.7777C19.8777 98.4647 20.9186 97.329 22.2537 97.3315Z"
        fill="white"
      />
      <path
        opacity="0.78"
        d="M22.7592 111.262C36.1096 111.271 87.3597 111.253 100.301 111.248C101.934 111.247 103.259 112.291 103.229 113.552C103.201 114.752 101.95 115.707 100.396 115.713L22.8971 116.018C21.4043 116.023 20.1356 115.149 19.9702 114.001L19.9283 113.71C19.7392 112.397 21.052 111.26 22.7592 111.262Z"
        fill="white"
      />
      <g opacity="0.74">
        <path
          opacity="0.78"
          d="M21.951 121.712C32.3919 121.732 72.4738 121.751 82.5952 121.756C83.8719 121.756 84.8952 122.801 84.8566 124.062C84.8199 125.262 83.829 126.215 82.6137 126.22L21.9997 126.467C20.8321 126.472 19.8507 125.597 19.7356 124.449L19.7065 124.158C19.575 122.845 20.6158 121.71 21.951 121.712Z"
          fill="white"
        />
      </g>
      <path
        opacity="0.48"
        d="M23.5464 135.476C36.8968 135.486 88.1469 135.467 101.088 135.462C102.721 135.461 104.046 136.505 104.016 137.766C103.989 138.966 102.737 139.921 101.183 139.927L23.6842 140.232C22.1914 140.238 20.9227 139.364 20.7572 138.215L20.7154 137.925C20.5264 136.612 21.8392 135.475 23.5464 135.476Z"
        fill="white"
      />
      <path
        opacity="0.36"
        d="M22.7924 147.046C33.1015 147.046 72.6772 146.993 82.6706 146.979C83.9312 146.977 84.9426 148.02 84.9057 149.28C84.8706 150.48 83.8931 151.436 82.6932 151.443L22.8451 151.801C21.6923 151.808 20.7225 150.934 20.6077 149.786L20.5787 149.496C20.4475 148.183 21.4742 147.046 22.7924 147.046Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_439_433"
          x1="60.37"
          y1="24.7551"
          x2="43.9049"
          y2="210.672"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0CD46" />
          <stop offset="1" stopColor="#DB9821" />
        </linearGradient>
      </defs>
    </svg>
  );
}
