import { GetTokenSilentlyOptions } from '@auth0/auth0-react';
import { createContext, useContext } from 'react';
import { AuthUtils } from '../../types';

const defaultValue: AuthUtils = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAccessToken: (options?: GetTokenSilentlyOptions) => Promise.reject(),
  logout: () => Promise.resolve(),
};
export const AuthUtilsContext = createContext(defaultValue);

export function useAuthUtils() {
  const authUtils = useContext(AuthUtilsContext);
  return authUtils;
}
