import DashboardHeader from './DashboardHeader';
import DasboardHeaderMobile from './DashboardHeaderMobile';

export function RootHeader() {
  return (
    <>
      <DashboardHeader />
      <DasboardHeaderMobile />
    </>
  );
}

export default RootHeader;
