import { UseQueryResult } from '@tanstack/react-query';

import { Alert } from '../../ui-base';
import Centered from '../centered/Centered';
import LoadingIndicator from '../loaders/LoadingIndicator';

interface Props<T> {
  queryResult: Pick<
    UseQueryResult<T, Error>,
    'data' | 'error' | 'isError' | 'isLoading' | 'isRefetching'
  >;
  children: (data: T) => JSX.Element;
  loadingText?: string;
  /**
   * When true keep content rendered on refetch and display loader over
   * it otherwise remove the content and display just the loader
   */
  keepCurrentContentOnRefetch?: boolean;
  errorFormatter?: (error: Error) => JSX.Element;
}

export function QueryResult<T>(props: Props<T>) {
  const {
    queryResult: { isLoading, error, data, isRefetching },
    children,
    loadingText,
    keepCurrentContentOnRefetch = true,
    errorFormatter,
  } = props;

  const loader = (
    <Centered>
      <LoadingIndicator text={loadingText} />
    </Centered>
  );

  return (
    <div className="relative h-full bg-inherit">
      {isRefetching && keepCurrentContentOnRefetch && (
        <>
          <div className="absolute inset-0 z-10 bg-gray-300 opacity-50" />
          <div className="absolute inset-0 z-10">{loader}</div>
        </>
      )}
      {isLoading || (isRefetching && !keepCurrentContentOnRefetch) ? (
        loader
      ) : error !== null ? (
        errorFormatter ? (
          errorFormatter(error)
        ) : (
          <div className="mx-auto max-w-screen-2xl p-6">
            <Alert type="error">{error.message ?? ''}</Alert>
          </div>
        )
      ) : !data ? (
        loader
      ) : (
        children(data)
      )}
    </div>
  );
}

export default QueryResult;
