import { DocToolParams, Headnote, JudgementMetadata } from '../../../types';

export const getFormatedCitedCases = (cited_cases = '') => {
  let updatedCiteCases = '';
  try {
    const citedCases: string[] = JSON.parse(cited_cases || '');

    updatedCiteCases = citedCases.reduce(
      (acc: string, c: string, index: number) => {
        const sn = index + 1;
        acc = `${acc} \n ${sn}. ${c}`;
        return acc;
      },
      '',
    );
  } catch (error) {
    updatedCiteCases = cited_cases || '';
  }
  return updatedCiteCases;
};

export function getSpecialFormatHeadnote(headnote: Headnote) {
  const { laws, topics, held, cited_cases, head_note } = headnote;
  let updatedString = '';
  if (laws && topics && held) {
    updatedString = `<div class='font-extrabold text-black'>${laws}. ${topics}</div> \n <span>${held}</span>`;
  } else {
    updatedString = head_note;
  }
  updatedString += `\n\n <span class='text-[12px]'>Cited Cases: ${getFormatedCitedCases(cited_cases)}</span>`;
  updatedString += `\n\n${getPartyName(headnote.judgement_metadata)}`;
  return updatedString;
}

export function getStandardHeadnote(headnote: Headnote) {
  const { head_note, cited_cases } = headnote;
  const updatedHeadnote =
    `<div class='font-bold text-black'>${head_note}</div>` +
    '\n' +
    'Cited Cases:' +
    getFormatedCitedCases(cited_cases);
  return updatedHeadnote;
}

export function getStandardFormatRawText(headnote: Headnote) {
  return (
    headnote?.head_note +
      '\n\n' +
      'Cited Cases:' +
      getFormatedCitedCases(headnote.cited_cases) || ''
  );
}

export function getFileName(fileName: string) {
  return fileName.split('.')[0];
}

interface SectionConfig {
  fontSize: number;
  isBold?: boolean;
  title?: string;
  extraLineBreaks?: number;
  isInline?: boolean;
}

interface SectionWiseFormat {
  [key: string]: SectionConfig;
}

const sectionWiseFormat: SectionWiseFormat = {
  laws: {
    fontSize: 12,
    isBold: true,
  },
  topics: {
    fontSize: 12,
    isInline: true,
  },
  held: {
    fontSize: 11,
    extraLineBreaks: 1,
  },
  cited_cases: {
    fontSize: 10,
    title: 'Cited Cases:',
    extraLineBreaks: 1,
  },
  party_name: {
    fontSize: 11,
    extraLineBreaks: 1,
    isBold: true,
  },
};

export const getPartyName = (metadata?: JudgementMetadata) => {
  if (!metadata) {
    return '';
  }
  const { case_no, petitioner, DD, respondent, arising } = metadata;
  // eslint-disable-next-line no-extra-boolean-cast
  const arisingText = !!arising ? arising + ', ' : '';
  return `[${petitioner} vs ${respondent}, ${case_no}, ${arisingText}DD: ${DD}]`;
};

const getSectionText = (
  headnote: Headnote,
  sectionKey: string,
  title?: string,
) => {
  let finalText = headnote[sectionKey as keyof Headnote];
  if (sectionKey === 'party_name' && headnote.judgement_metadata) {
    finalText = getPartyName(headnote.judgement_metadata);
  }
  if (sectionKey === 'cited_cases') {
    finalText = getFormatedCitedCases(headnote['cited_cases']);
  }
  return `${title || ''}` + finalText;
};

export const getHeadnoteFormattedDocConfig = (headnote: Headnote) => {
  const { case_type, main_law, file_name } = headnote;

  const exportFileName =
    case_type + '-' + main_law + '-' + getFileName(file_name) || 'headnote';

  let formattedSections = Object.entries(sectionWiseFormat).map(
    ([key, val]: [string, SectionConfig]) => {
      return {
        fontSize: val.fontSize,
        isBold: val.isBold,
        text: getSectionText(headnote, key, val.title),
        isInline: val.isInline,
        extraLineBreaks: val.extraLineBreaks,
      };
    },
  );

  formattedSections = formattedSections.filter(
    (section) => section.text !== 'undefined',
  );

  const docCustomConfig = {
    font: 'palatino',
    fontSize: 11,
    sections: formattedSections,
  };

  const finalConfig: DocToolParams = {
    filename: exportFileName,
    textWithCustomFormat: docCustomConfig,
  };

  return finalConfig;
};
