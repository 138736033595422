import { LogoutOptions } from '@auth0/auth0-react';

export const GENERIC_USER_MESSAGE = 'An error occurred';
export const MISSING_ERROR_CAUSE = 'Missing error cause in response';

export function handleUnauthorized(logout: (options?: LogoutOptions) => void) {
  return async (response: Response) => {
    if (!response.ok && response.status === 401) {
      logout({ logoutParams: { returnTo: window.location.origin } });
    }

    return response;
  };
}

export async function handleResponse(response: Response) {
  if (!response.ok) {
    let res;

    try {
      res = await response.json();
    } catch (err) {
      throw new Error(GENERIC_USER_MESSAGE);
    }

    if (!res.message) {
      throw new Error(GENERIC_USER_MESSAGE);
    }

    throw new Error(res.message);
  }

  return response;
}
