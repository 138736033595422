import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { useUploadDocumentForHeadnote } from '../../data-access';
import { CreateHeadnoteResponse } from '../../types';
import { Alert, Button } from '../../ui-base';
import { LoadingImage, UploadCloudIcon } from '../../ui-elements';
import { toast } from '../../util';

export function CreateHeadnote() {
  const navigate = useNavigate();

  const onUploadSuccess = (val: CreateHeadnoteResponse) => {
    toast('Document uploaded successfully');
    navigate(`/dashboard/headnote/details/${val.document_id}?referFrom=create`);
  };

  const uploadDocumentMutation = useUploadDocumentForHeadnote(onUploadSuccess);

  const handleFileDrop = (
    event:
      | React.DragEvent<HTMLDivElement>
      | React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files =
      event.type === 'drop'
        ? (event as React.DragEvent<HTMLDivElement>).dataTransfer.files
        : (event as React.ChangeEvent<HTMLInputElement>).target.files;
    if (files) {
      const formData = new FormData();
      formData.append('file', files[0] || '');
      uploadDocumentMutation.mutate(formData);
    }
  };

  const onNavBack = () => {
    navigate(-1);
  };

  if (uploadDocumentMutation.isPending) {
    return (
      <div className="m-8 w-full flex-1 px-20 py-28 shadow-lg">
        <h2 className="mb-12 text-xl font-bold text-lawmatrix-purple lg:text-2xl">
          Creating Headnote
        </h2>
        <LoadingImage />
      </div>
    );
  }

  return (
    <div className="m-2 w-full flex-1 px-4 py-4 shadow-lg lg:m-8 lg:px-20 lg:py-12">
      <div className="mb-4 flex items-center gap-2">
        <Button variant="icon" onClick={onNavBack}>
          <ArrowLeftIcon className="h-8 w-8" />
        </Button>
        <h2 className="text-xl font-bold text-lawmatrix-purple lg:text-2xl">
          Create Headnote
        </h2>
      </div>
      {uploadDocumentMutation.isError && (
        <Alert type="error">{uploadDocumentMutation.error.message}</Alert>
      )}
      <div className="flex flex-col items-center justify-center gap-4 rounded border-2 border-dashed border-gray-200 p-4 lg:gap-8 lg:p-16">
        <UploadCloudIcon className="h-16 w-16 text-lawmatrix-purple lg:h-24 lg:w-24" />
        <span className="text-xl text-gray-900 lg:text-3xl">
          Select and upload your file to create headnote
        </span>
        <span className="text-gray-500">DOCX,PDF files are supported</span>
        <div className="relative w-40 rounded-md bg-lawmatrix-purple p-2 text-center text-gray-100 shadow-md hover:bg-[#3c3268] lg:w-52 lg:p-5">
          <input
            type="file"
            accept=".pdf,.txt"
            onChange={handleFileDrop}
            className="absolute inset-0 cursor-pointer opacity-0"
          />
          Select File
        </div>
        <a
          href="/sample-judgement.pdf"
          download="Sample-Judgment.pdf"
          className="flex items-center gap-2 rounded border-transparent px-2 text-blue-500 hover:underline"
        >
          <ArrowDownTrayIcon className="h-5 w-5" />
          <span>
            Download a sample judgement document to try out headnote creation
          </span>
        </a>
      </div>
    </div>
  );
}

export default CreateHeadnote;
