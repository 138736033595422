import {
  BuildingOffice2Icon,
  EnvelopeIcon,
  PhoneIcon,
} from '@heroicons/react/24/solid';
import { Footer, Header } from '../../ui-elements';
import { useScrollToTopOnLoad } from '../../util';

const details = [
  {
    id: 'address',
    title: 'Our Location',
    icon: BuildingOffice2Icon,
    details: (
      <span>
        B-103 Mana Karmel, Kodathi behind Decathlon, Carmelram, Bangalore South,
        Bangalore- 560035, Karnataka
      </span>
    ),
  },
  {
    id: 'phoneNumber',
    title: 'Phone Number',
    icon: PhoneIcon,
    details: (
      <span>
        +91 9036152962 <br />
        9:00 AM to 7:00 PM
      </span>
    ),
  },
  {
    id: 'email',
    title: 'Email Address',
    icon: EnvelopeIcon,
    details: (
      <span>
        <a
          href="mailto:support@lawmatrix.ai"
          className="text-blue-500 hover:underline"
        >
          support@lawmatrix.ai
        </a>
      </span>
    ),
  },
];

export function ContactUs() {
  useScrollToTopOnLoad();
  return (
    <>
      <Header />
      <div className="px-6 py-4 md:px-20 md:py-14">
        <h1 className="mb-8 text-center text-4xl text-[#1E0E62]">Contact Us</h1>
        <div className="my-4 text-center text-xl">
          Lets start the conversation
        </div>
        <div className="my-8 flex flex-col gap-6 lg:ml-[20rem]">
          {details.map((det) => {
            const DetIcon = det.icon;
            return (
              <div key={det.id} className="flex items-center gap-8">
                <DetIcon className="h-6 w-6 text-lawmatrix-purple lg:h-12 lg:w-12" />
                <div className="flex flex-1 flex-col gap-4">
                  <span className="text-lg font-bold text-black">
                    {det.title}
                  </span>
                  {det.details}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactUs;
