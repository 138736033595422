import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
}

export const Container = (props: Props) => {
  return (
    <div className={`container mx-auto px-[16px] ${props?.className}`}>
      {props?.children}
    </div>
  );
};

export default Container;
