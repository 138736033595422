import { UserCircleIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { MenuDropdown } from '../../../ui-elements';
import { useAuthUtils, useUser } from '../../../util';

export function UserMenu() {
  const { logout } = useAuthUtils();
  const { name, picture } = useUser();

  const onLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const userMenuItems = [
    {
      name: 'Logout',
      onClick: onLogout,
    },
  ];

  const menuButton = (
    <>
      <span className="sr-only">Open user menu</span>
      {picture ? (
        <img className="h-8 w-8 rounded-full" src={picture} alt="user avatar" />
      ) : (
        <UserCircleIcon className="h-8 w-8" />
      )}

      <span className="mx-2 flex items-center gap-2">
        <span className="text-sm leading-6" aria-hidden="true">
          {name}
        </span>
        <span>
          <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
        </span>
      </span>
    </>
  );

  return <MenuDropdown items={userMenuItems} menuButton={menuButton} />;
}

export default UserMenu;
