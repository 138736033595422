import { Link } from 'react-router-dom';
import { Container } from '../../ui-base';

export const Footer = () => {
  return (
    <footer className="bg-[#1F1938] py-[45px] text-[#C3C0CD] xl:py-[67px]">
      <Container>
        <div className="items-center justify-between space-y-[32px] px-[15px] xl:flex xl:space-y-0">
          <Link to="/">
            <img src="/images/logo/white-logo.svg" alt="white-logo" />
          </Link>
          <ul className="flex flex-wrap items-center justify-center gap-2 space-x-[16px] xl:space-x-[47px] xl:text-base">
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/terms-and-conditions">Terms and Conditions</Link>
            </li>
            <li>
              <Link to="/refund-policy">Refund Policy</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact Us</Link>
            </li>
          </ul>
        </div>
        <hr className="my-[32px] border-[#7E7B90] xl:my-[40px]" />
        <p className="px-[15px] xl:text-base">
          (c) 2024 Lexgen Technologies Pvt. Ltd. All Rights Reserved
        </p>
      </Container>
    </footer>
  );
};
