import { Field, Label, Switch } from '@headlessui/react';
import clsx from 'clsx';

interface Props {
  enabled: boolean;
  onChange: (val: boolean) => void;
  label?: string;
}

export function Toggle(props: Props) {
  const { enabled, onChange, label } = props;
  return (
    <Field className="flex items-center gap-2">
      <Switch
        checked={enabled}
        onChange={onChange}
        className={clsx(
          enabled ? 'bg-blue-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-auto',
        )}
      >
        <span
          aria-hidden="true"
          className={clsx(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        />
      </Switch>
      <Label>{label}</Label>
    </Field>
  );
}

export default Toggle;
