import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode } from 'react';
import Auth0ProviderWithRedirectCallback from './Auth0ProviderWithRedirectCallback';
import AuthUtilsProvider from './AuthUtilsProvider';

interface Props {
  children: ReactNode;
}

export const AuthProvider = (props: Props) => {
  const { children } = props;

  return (
    <Auth0ProviderWithRedirectCallback>
      <Auth0Auth>{children}</Auth0Auth>
    </Auth0ProviderWithRedirectCallback>
  );
};

function Auth0Auth({ children }: { children: ReactNode }) {
  const { getAccessTokenSilently, logout, user } = useAuth0();

  return (
    <AuthUtilsProvider
      authUtils={{
        getAccessToken: getAccessTokenSilently,
        logout: logout,
        profilePic: user?.picture,
      }}
    >
      {children}
    </AuthUtilsProvider>
  );
}
