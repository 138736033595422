import React from 'react';
import { Link } from 'react-router-dom';
import Container from '../container/Container';
interface BreadCrumbProps {
  label: string;
}
export const BreadCrumb: React.FC<BreadCrumbProps> = ({ label }) => (
  <div>
    <Container>
      <Link to="/" className="text-[#9497A1]">
        Home
      </Link>
      <span className="px-[10px] text-[#9497A1]">|</span>
      <span className="font-bold text-[#292D33]">{label}</span>
    </Container>
  </div>
);
