export function getAllSections() {
  return [
    {
      id: 'section1',
      title: 'Introduction',
      content: (
        <p>
          Thank you for choosing{' '}
          <b className="text-black">Lexgen Technologies Private Limited</b> for
          your software as a service (SaaS) needs. We strive to provide the best
          service and support for our users. However, due to the nature of our
          product, we have a strict No Refund Policy. Please read this policy
          carefully before subscribing to our services.{' '}
        </p>
      ),
    },
    {
      id: 'section2',
      title: 'No Refunds',
      content: (
        <div className="prose">
          All purchases of our SaaS product are final, and we do not offer
          refunds or exchanges for any payments made. This includes but is not
          limited to:
          <br />
          <ul className="ml-5">
            <li>1. Recharge Packs</li>
            <li>2. One-Time Payments</li>
            <li>3. Any other charges related to the use of our services</li>
          </ul>
        </div>
      ),
    },
    {
      id: 'section3',
      title: 'Free Trial and Demos',
      content: (
        <p>
          We encourage you to take advantage of any free trials or demos we may
          offer before committing to a subscription. These trials provide an
          opportunity to evaluate our services and ensure they meet your needs
          and expectations.
        </p>
      ),
    },
    {
      id: 'section4',
      title: 'Exceptions',
      content: (
        <p>
          In certain exceptional circumstances, we may consider refund requests
          on a case-by-case basis. These exceptions are at the sole discretion
          of <b className="text-black">Lexgen Technologies Private Limited</b>{' '}
          and are not guaranteed. To request an exception, please contact our
          support team with a detailed explanation of your situation.
        </p>
      ),
    },
    {
      id: 'section5',
      title: 'Changes to this Policy',
      content: (
        <p>
          We reserve the right to modify or update this No Refund Policy at any
          time. Any changes will be posted on our website, and your continued
          use of our services constitutes your acceptance of the revised policy.{' '}
        </p>
      ),
    },
    {
      id: 'section6',
      title: 'Contact Us',
      content: (
        <p>
          If you have any questions or concerns about this No Refund Policy,
          please contact our support team at support@lawmatrix.ai.
          <br />
          <br />
          By subscribing to our services, you acknowledge that you have read,
          understood, and agreed to this No Refund Policy.
          <br />
          <br />
          Thank you for your understanding and for choosing{' '}
          <b className="text-black">Lexgen Technologies Private Limited.</b>
          <br />
          <br />
          <b className="text-black">Lexgen Technologies Private Limited</b>
          <br />
          B-103 1ST FLR MANA KARMEL,
          <br />
          KODATHI BEHIND DECATHOLON,
          <br />
          Carmelram, Bangalore South,
          <br />
          Bangalore- 560035, Karnataka
          <br />
          Email: support@lawmatrix.ai
        </p>
      ),
    },
  ];
}
