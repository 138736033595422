import { ClockIcon, Squares2X2Icon } from '@heroicons/react/24/solid';
import { NavItem } from '../../../types';

export const navItems: NavItem[] = [
  {
    id: 'home',
    name: 'Headnote',
    href: '/dashboard',
    icon: Squares2X2Icon,
  },
  {
    id: 'history',
    name: 'History',
    href: '/dashboard/history',
    icon: ClockIcon,
  },
];
