// src/components/Table.tsx
import clsx from 'clsx';
import { useState } from 'react';
import { TableColumn, TableProps } from './types';

export const Table = <T,>({
  data,
  columns,
  className,
  onRowClick,
}: TableProps<T>) => {
  const [sortConfig, setSortConfig] = useState<{
    key: keyof T;
    direction: 'asc' | 'desc';
  } | null>(null);

  const getSortableData = () => {
    if (!sortConfig) return data;
    return [...data].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSort = (column: TableColumn<T>) => {
    if (!column.sortable) return;
    const key = column.accessor as keyof T;
    let direction: 'asc' | 'desc' = 'asc';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'asc'
    ) {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const renderCell = (row: T, column: TableColumn<T>) => {
    const value =
      typeof column.accessor === 'function'
        ? column.accessor(row)
        : row[column.accessor];
    if (column.render) {
      return column.render(value, row);
    }
    return value;
  };

  return (
    <>
      <table
        className={clsx('min-w-full divide-y divide-gray-200', className || '')}
      >
        <thead className="bg-gray-50">
          <tr>
            {columns.map((column) => (
              <th
                key={column.header}
                style={column.style}
                className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 ${column.sortable ? 'cursor-pointer' : ''}`}
                onClick={() => handleSort(column)}
              >
                {column.header}{' '}
                {column.sortable &&
                  (sortConfig?.key === column.accessor
                    ? sortConfig.direction === 'asc'
                      ? '▲'
                      : '▼'
                    : '↕')}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {getSortableData().map((row, rowIndex) => (
            <tr
              className="hover:bg-gray-100"
              key={rowIndex}
              onClick={() => onRowClick && onRowClick(row)}
            >
              {columns.map((column, colIndex) => (
                <td
                  key={colIndex}
                  style={column.style}
                  className="whitespace-normal px-6 py-4"
                >
                  {renderCell(row, column)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {data && data.length === 0 && (
        <div className="w-full p-6 text-center text-gray-400">
          No Headnotes yet. Start creating
        </div>
      )}
    </>
  );
};

export default Table;
