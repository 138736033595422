import { Link, useLocation } from 'react-router-dom';
import { Button } from '../../../ui-base';
import { LawMatrixLogo } from '../../../ui-elements';
import UserMenu from './UserMenu';

export function DashboardHeader() {
  const location = useLocation();

  const showCreateButton =
    ['/dashboard', '/dashboard/create-headnote'].indexOf(location.pathname) ===
    -1;

  return (
    <div className="hidden items-center justify-between border-b-2 border-gray-300 lg:flex">
      <div className="w-64 border-r-2 border-gray-300 p-6">
        <Link to="/dashboard">
          <LawMatrixLogo />
        </Link>
      </div>
      <div className="mr-2 flex items-center gap-8">
        {showCreateButton && (
          <Button variant="primary">
            <Link to="/dashboard/create-headnote">Create New Headnote</Link>
          </Button>
        )}
        <UserMenu />
      </div>
    </div>
  );
}
export default DashboardHeader;
