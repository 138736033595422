import { HeadnotesDTO } from '../../types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useHeadnotes() {
  const url = `/api/v1/documents`;
  const request = new Request(url);

  return useAuthenticatedRequest<HeadnotesDTO[]>(request, {
    queryKey: ['headnotes'],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson;
    },
  });
}

export default useHeadnotes;
