import { UserProfileProvider } from '../../providers';
import DashboardRoutes from './DashboardRoutes';
import Toaster from './Toaster';
import RootHeader from './header/RootHeader';
import Navbar from './navbar/Navbar';

export function DashboardRoot() {
  return (
    <UserProfileProvider>
      <RootHeader />
      <div className="flex w-full">
        <Navbar />
        <DashboardRoutes />
      </div>
      <Toaster />
    </UserProfileProvider>
  );
}
export default DashboardRoot;
