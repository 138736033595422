import { createContext, useContext } from 'react';
import { HttpClient } from '../../types';

const defaultValue: HttpClient = () =>
  Promise.reject('Unexpected use of HttpClient before initialization');

export const HttpClientContext = createContext(defaultValue);

export function useHttpClient() {
  const httpClient = useContext(HttpClientContext);
  return httpClient;
}
