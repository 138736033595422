import { CreateHeadnoteResponse } from '../../types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useUploadDocumentForHeadnote(
  onSuccess: (data: CreateHeadnoteResponse) => void,
) {
  const url = `/api/v1/documents`;

  const request = new Request(url, {
    method: 'POST',
  });

  return useAuthenticatedMutation<CreateHeadnoteResponse, FormData>(request, {
    onSuccess,
    getBody: (data) => data,
  });
}

export default useUploadDocumentForHeadnote;
