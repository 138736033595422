import Spinner from './Spinner';

export function LawMatrixSpinner(props: React.ComponentProps<'span'>) {
  return (
    <span className="text-lawmatrix-purple" {...props}>
      <Spinner />
    </span>
  );
}

export default LawMatrixSpinner;
