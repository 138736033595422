import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { NavItem } from '../../../types';

interface Props {
  items: NavItem[];
  onClick?: () => void;
  short?: boolean;
  isMobile?: boolean;
}

function Links(props: Props) {
  const { items, short, onClick = () => null, isMobile } = props;

  const desktopActiveStyle =
    'text-lawmatrix-purple border-lawmatrix-purple border-l-2 bg-[#f4f3f8]';

  const mobileActiveStyle = 'text-lawmatrix-purple text-lg';

  const activeStyle = isMobile ? mobileActiveStyle : desktopActiveStyle;

  return (
    <nav>
      <div className="space-y-1">
        {items.map((item) => (
          <NavLink
            key={item.name}
            to={item.href}
            end
            title={item.name}
            className={({ isActive }) =>
              clsx(
                isActive ? activeStyle : 'text-gray-500 hover:text-gray-700 ',
                'focus-visible:ring-primary-500 group flex items-center rounded-lg p-4 text-base font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
              )
            }
            onClick={onClick}
          >
            {({ isActive }) => (
              <>
                <item.icon
                  className={clsx(
                    isActive
                      ? 'text-lawmatrix-purple'
                      : 'text-gray-500 group-hover:text-gray-700',
                    'mr-3 h-6 w-6 flex-shrink-0',
                  )}
                  aria-hidden="true"
                />
                {short ? '' : item.name}
              </>
            )}
          </NavLink>
        ))}
      </div>
    </nav>
  );
}

export default Links;
