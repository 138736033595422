import { GetTokenSilentlyOptions, LogoutOptions } from '@auth0/auth0-react';
import { HttpClient, RequestOptions } from '../../types';
import { handleResponse, handleUnauthorized } from './error';

export function createHttpClient(
  getAccessTokenSilently: (
    options?: GetTokenSilentlyOptions,
  ) => Promise<string>,
  logout: () => void,
): HttpClient {
  return async <T>(
    requestInfo: RequestInfo,
    options: RequestOptions<T> = {},
  ) => {
    const { noAuth = false } = options;

    const request = await getRequest(
      requestInfo,
      getAccessTokenSilently,
      noAuth,
      logout,
    );

    const modifyResponse =
      options?.responseModifier ??
      ((response: Response) => {
        return response.json();
      });

    function tap(response: Response) {
      options.tap && options.tap(response.clone());
      return response;
    }

    if (noAuth) {
      return fetch(request).then(handleResponse).then(tap).then(modifyResponse);
    }

    return fetch(request)
      .then(handleUnauthorized(logout))
      .then(handleResponse)
      .then(tap)
      .then(modifyResponse);
  };
}

async function getRequest(
  requestInfo: RequestInfo,
  getAccessTokenSilently: (
    options?: GetTokenSilentlyOptions,
  ) => Promise<string>,
  noAuth: boolean,
  logout: (options?: LogoutOptions) => void,
) {
  // const { getAccessTokenSilently } = useAuth0();
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE ?? '';

  if (noAuth) {
    return new Request(requestInfo);
  } else {
    let accessToken = '';

    try {
      accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: audience,
          scope: 'read:current_user update:current_user_metadata',
        },
      });
    } catch (error) {
      console.error(error);
      logout({ logoutParams: { returnTo: window.location.origin } });
      throw Error('Failed to perform authenticated request');
    }

    const request = new Request(requestInfo);
    request.headers.set('Authorization', `Bearer ${accessToken}`);

    return request;
  }
}
