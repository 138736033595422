import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { useHttpClient } from '../../util';

type RequestOptions<TData, TVariables> = UseMutationOptions<
  TData,
  Error,
  TVariables
> & {
  responseModifier?: (response: Response) => Promise<TData>;
  getBody?: (data: TVariables) => BodyInit | null;
  onSuccessInvalidationKeys?: string[][];
  /** Make an unauthenticated request by skipping the Authorization header */
  noAuth?: boolean;
};

export function useAuthenticatedMutation<TData, TVariables>(
  requestInfo: Request,
  options: RequestOptions<TData, TVariables> = {},
) {
  const queryClient = useQueryClient();
  const httpClient = useHttpClient();
  const { responseModifier, getBody, onSuccess, onSuccessInvalidationKeys } =
    options;

  const mutationFn: MutationFunction<TData, TVariables> = async (data) => {
    const request = new Request(requestInfo, {
      body: getBody ? getBody(data) : JSON.stringify(data),
    });

    return httpClient<TData>(request, {
      noAuth: options?.noAuth,
      responseModifier,
    });
  };

  return useMutation<TData, Error, TVariables>({
    mutationFn,
    ...options,
    onSuccess: (data, variables, context) => {
      onSuccessInvalidationKeys?.forEach((item) => {
        queryClient.invalidateQueries({
          queryKey: item,
        });
      });
      if (onSuccess) {
        onSuccess(data, variables, context);
      }
    },
  });
}

export default useAuthenticatedMutation;
