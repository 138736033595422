import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useHeadnoteById } from '../../../data-access';
import { Alert, Button } from '../../../ui-base';
import { CardPanel, DocViewer, LoadingImage } from '../../../ui-elements';
import { toast } from '../../../util';
import { exportToPDF } from '../../../util/pdf-tools';
import {
  getFileName,
  getFormatedCitedCases,
  getHeadnoteFormattedDocConfig,
  getPartyName,
  getSpecialFormatHeadnote,
  getStandardFormatRawText,
  getStandardHeadnote,
} from './util';

// on router path /headnote/details/:docId?refeFrom=
export function HeadnoteDetails() {
  const navigate = useNavigate();
  const { docId } = useParams();
  const { data, isLoading, isError, error } = useHeadnoteById(docId || '');
  const [uploadedDocumentLink, setUploadedDocumentLink] = useState('');
  const [headnote, setHeadnote] = useState('');
  const searchParams = new URLSearchParams(window.location.search);
  const isReferFromCreate = searchParams.get('referFrom') === 'create';
  const [isStandardFormat, setIsStandardFormat] = useState(false);

  useEffect(() => {
    if (data) {
      setUploadedDocumentLink(data.s3_url);
      let updatedHeadnote = '';
      if (isStandardFormat) {
        updatedHeadnote = getStandardHeadnote(data);
      } else {
        updatedHeadnote = getSpecialFormatHeadnote(data);
      }
      setHeadnote(updatedHeadnote);
    }
  }, [data, isStandardFormat]);

  const onToggleChange = (isEnabled: boolean) => {
    setIsStandardFormat(isEnabled);
  };

  const onNavBack = () => {
    navigate('/dashboard/history');
  };

  const onExportDoc = () => {
    if (!data) {
      return;
    }
    let docConfig = {};
    if (isStandardFormat) {
      docConfig = {
        text: getStandardFormatRawText(data),
        filename: `${getFileName(data.file_name)}-headnote`,
      };
    } else {
      docConfig = getHeadnoteFormattedDocConfig(data);
    }
    exportToPDF(docConfig);
  };

  const onCopyDoc = () => {
    if (!data) return;
    // standard note
    let text = getStandardFormatRawText(data);
    if (!isStandardFormat) {
      // special format
      const { laws, topics, held, cited_cases } = data;
      text = `${laws}. ${topics} \n ${held} \n\n Cited Cases: ${getFormatedCitedCases(cited_cases)} \n\n${getPartyName(data.judgement_metadata)}`;
    }
    navigator.clipboard.writeText(text).then(
      () => {
        toast('Headnote copied to clipboard');
      },
      () => {
        toast('Failed to copy text');
      },
    );
  };

  return (
    <div className="m-2 w-full flex-1 px-6 py-4 lg:px-4 lg:shadow-lg">
      {isError && <Alert type="error">{error.message}</Alert>}
      {isLoading ? (
        <LoadingImage />
      ) : (
        <>
          <div className="flex items-center gap-2">
            <Button
              variant="icon"
              onClick={onNavBack}
              className={clsx(isReferFromCreate && 'hidden')}
            >
              <ArrowLeftIcon className="h-8 w-8" />
            </Button>
            <h2 className="text-xl text-lawmatrix-purple">Created Headnote</h2>
          </div>
          <div className="flex flex-wrap gap-6 lg:min-h-[30rem] lg:flex-nowrap">
            <div className="flex-1">
              <DocViewer
                fileUrl={uploadedDocumentLink}
                title="Uploaded Judgement"
              />
            </div>
            <div className="flex-1">
              <CardPanel
                title="Headnote"
                description={headnote}
                isDownloadable
                toggleEnabled={isStandardFormat}
                onToggleChange={onToggleChange}
                toggleLabel="Standard format"
                onExport={onExportDoc}
                onCopy={onCopyDoc}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default HeadnoteDetails;
