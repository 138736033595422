import { ReactElement } from 'react';
import { HttpClientContext, createHttpClient, useAuthUtils } from '../../util';

interface Props {
  children: ReactElement;
}

export function HttpClientProvider(props: Props) {
  const { children } = props;
  const { getAccessToken, logout } = useAuthUtils();
  const httpClient = createHttpClient(getAccessToken, logout);

  return (
    <HttpClientContext.Provider value={httpClient}>
      {children}
    </HttpClientContext.Provider>
  );
}

export default HttpClientProvider;
