import { useAuth0 } from '@auth0/auth0-react';
import * as React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Container } from '../../ui-base';

export const Header = () => {
  const [open, setOpen] = React.useState(false);
  const { loginWithRedirect } = useAuth0();

  React.useMemo(() => {
    if (open) {
      document.querySelector('body')?.classList.add('overflow-hidden');
    } else {
      document.querySelector('body')?.classList.remove('overflow-hidden');
    }
  }, [open]);
  return (
    <>
      <header className="relative py-[24px]">
        <Container className="flex items-center justify-between">
          <Link to="/">
            <img src="/images/logo/logo.svg" alt="logo" />
          </Link>
          <span
            className="block cursor-pointer xl:hidden"
            onClick={() => setOpen(!open)}
          >
            <img
              src={`/images/header/${!open ? 'hamburgermenu' : 'filled'}.svg`}
              alt="hamburgermenu"
            />
          </span>
          <ul
            className={`mobileMenu items-center xl:flex xl:space-x-[32px] ${
              open ? 'Active' : ''
            }`}
          >
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            {/* <li>
              <Link to="#!" className="flex">
                Products{' '}
                <img
                  className="ml-2"
                  src="/images/header/chevronDown.svg"
                  alt="chevronDown"
                />
              </Link>
            </li> */}
            <li>
              <Link
                onClick={() => loginWithRedirect()}
                to=""
                className="xl:ml-[107px]"
              >
                Login
              </Link>
            </li>
            <li className="mobBtn">
              <Link
                to=""
                onClick={() => loginWithRedirect()}
                className="block rounded-[4px] bg-[#4C417E] px-[36px] py-[15.5px] text-center font-bold text-white shadow-[0_1px_5px_0_rgba(0,0,0,0.12),0_2px_2px_0_rgba(0,0,0,0.14),0_3px_1px_-2px_rgba(0,0,0,0.2)]"
              >
                Signup for Free
              </Link>
            </li>
          </ul>
        </Container>
      </header>
    </>
  );
};

export default Header;
