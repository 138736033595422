import { useNavigate } from 'react-router-dom';
import { NotesIcon } from '../../ui-elements';
import FeatureCard from '../../ui-elements/feature-card/FeatureCard';

const allFeatures = [
  {
    id: 'createHeadnote',
    icon: NotesIcon,
    title: 'Headnote Creator',
    actionText: 'Create',
    navTo: '/dashboard/create-headnote',
  },
];

export function DashboardHome() {
  const navigate = useNavigate();

  return (
    <div className="m-2 w-full flex-1 px-4 py-4 lg:m-8 lg:px-20 lg:py-24 lg:shadow-lg">
      {allFeatures.map((feat) => (
        <FeatureCard
          key={feat.id}
          title={feat.title}
          actionText={feat.actionText}
          icon={feat.icon}
          onClick={() => navigate(feat.navTo)}
        />
      ))}
    </div>
  );
}

export default DashboardHome;
