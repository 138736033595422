import { TableColumn } from '../../ui-elements';

export interface HeadnoteHistory {
  sn: number;
  detail: string;
  date: string;
  id?: string;
}

export const myHeadnoteColumns: TableColumn<HeadnoteHistory>[] = [
  { header: 'SN', accessor: 'sn', sortable: true },
  { header: 'File name', accessor: 'detail' },
  { header: 'Uploaded Date', accessor: 'date', sortable: true },
];
