import { ReactNode } from 'react';
import { AuthUtils } from '../../types';
import { AuthUtilsContext } from '../../util';

interface Props {
  children: ReactNode;
  authUtils: AuthUtils;
}

const AuthUtilsProvider = (props: Props) => {
  const { children, authUtils } = props;

  return (
    <AuthUtilsContext.Provider value={authUtils}>
      {children}
    </AuthUtilsContext.Provider>
  );
};

export default AuthUtilsProvider;
