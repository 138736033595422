import { ReactNode } from 'react';
import { useProfile } from '../data-access';
import { User } from '../types';
import { QueryResult } from '../ui-elements';
import { UserProfileContext } from '../util/contexts/user-profile';

interface Props {
  children: ReactNode;
}

export function UserProfileProvider(props: Props) {
  const { children } = props;
  const result = useProfile();
  return (
    <div className="dark:bg-dark-bg h-[100vh]">
      <QueryResult queryResult={result} loadingText="Loading...">
        {(data: User) => (
          <UserProfileContext.Provider value={data}>
            {children}
          </UserProfileContext.Provider>
        )}
      </QueryResult>
    </div>
  );
}
